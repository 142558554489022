import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { HR } from "./common";
import { media } from "../css/theme";

const ImageCardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  max-width: 600px;
  ${props => props.theme.card1};
  h2 {
    margin-top: 0;
  }
  section {
    padding: 16px;
  }
  && ol {
    padding-left: 24px;
    margin-bottom: 0px;
  }
  button {
    width: 100%;
    margin-top: 16px;
  }
  ${media.tablet`
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: initial;
    align-items: center;
    justify-content: space-between;
    max-width: initial;
    section {
      padding: 0px;
      margin-left: 32px;
    }
    button {
        width: initial;
    }
  `};
`;

const CardImage = styled.div`
  width: 100% !important;
  height: 150px !important;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  img {
    object-position: top left !important;
  }
  ${media.small_tablet`
    height: 250px !important;
  `};
  ${media.tablet`
    border-radius: 8px;
    width: 300px !important;
    height: 300px !important;
    ${props => props.theme.card2}
  `};
  ${media.desktop`
    width: 400px !important;
    height: 400px !important;
  `};
`;

const ImageCard = ({
  fixed,
  title,
  body,
  children,
  justifyRight,
  alt,
  className
}) => {
  return (
    <ImageCardContainer justifyRight={justifyRight} className={className}>
      {fixed && <CardImage as={Img} fixed={fixed} alt={alt} />}
      <section>
        <h2>{title}</h2>
        <HR />
        <div dangerouslySetInnerHTML={{ __html: body }} />
        {children}
      </section>
    </ImageCardContainer>
  );
};

export default ImageCard;

ImageCard.propTypes = {
  fixed: PropTypes.object,
  title: PropTypes.string.isRequired,
  justifyRight: PropTypes.bool,
  alt: PropTypes.string,
  body: PropTypes.string.isRequired
};
