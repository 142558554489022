import styled from "styled-components";
import { media } from "../css/theme";

// PLACE <a> TAGS AS CHILDREN
const StyledButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  min-height: 36px;
  background-color: transparent;
  font-weight: 700;
  transition: all 0.25s;
  color: ${props =>
    props.color === "red" ? props.theme.red_2 : props.theme.teal_2};
  border: 2px solid
    ${props => (props.color === "red" ? props.theme.red_2 : props.theme.teal_2)};
  && a {
    color: ${props =>
      props.color === "red" ? props.theme.red_2 : props.theme.teal_2};
    ${media.tablet`padding: 8px;`};
  }
  &&:hover {
    ${props => props.theme.card2};
    && a {
      color: inherit !important;
    }
  }
`;

export default StyledButton;
