import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import { PageHeader, Divider, PageContainer } from "../components/common";
import StyledButton from "../components/StyledButton";
import SquareContentBox, {
  CardContainer
} from "../components/SquareContentBox";
import easy_icon from "../images/round-category-24px_teal.svg";
import responsive_icon from "../images/round-devices-24px_orange.svg";
import flexible_icon from "../images/round-edit_location-24px_red.svg";
import Img from "gatsby-image";
import styled from "styled-components";
import ImageCard from "../components/ImageCard";
import LocalLink from "../i18n/Link";
import { media } from "../css/theme";

const Mockup = styled.div`
  max-width: 100%;
  flex-shrink: 0;
`;

const StyledDivider = styled(Divider)`
  ${media.tablet`
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  `};
`;

// NOTES:
// Link is gatsby (local only), LocalLink splices in the locale, externals us <a>
const IndexPage = ({ content, mockup_1, berlin_demo, crowdmap }) => (
  <PageContainer>
    <PageHeader>
      <h1>{content.title}</h1>
      <h4>{content.subtitle}</h4>
      <div>
        <StyledButton color="red">
          <a href="https://canvis.app/demo">{content.demo_btn}</a>
        </StyledButton>
        <StyledButton>
          <a href="https://canvis.app">{content.launch_btn}</a>
        </StyledButton>
      </div>
    </PageHeader>
    <Divider>
      <ImageCard
        title={content.blurb_3_title}
        body={content.blurb_3_body}
        alt={content.blurb_3_img_alt}
        fixed={berlin_demo.childImageSharp.fixed}
      >
        <StyledButton>
          {/* might need to replace this to="" with a localized airtable link... */}
          <LocalLink to="/guide/case-studies">
            {content.case_studies_btn}
          </LocalLink>
        </StyledButton>
      </ImageCard>
    </Divider>
    <StyledDivider>
      <Mockup
        as={Img}
        fixed={mockup_1.childImageSharp.fixed}
        alt={content.blurb_2_img_alt}
      />
      <ImageCard title={content.blurb_2_title} body={content.blurb_2_body} />
    </StyledDivider>
    <Divider>
      <CardContainer>
        <SquareContentBox
          title={content.card_left_title}
          body={content.card_left_body}
          icon={easy_icon}
        />
        <SquareContentBox
          title={content.card_mid_title}
          body={content.card_mid_body}
          icon={responsive_icon}
        />
        <SquareContentBox
          title={content.card_right_title}
          body={content.card_right_body}
          icon={flexible_icon}
        />
      </CardContainer>
      <StyledButton color="red">
        <LocalLink to="/features">{content.see_features_btn}</LocalLink>
      </StyledButton>
    </Divider>
    <Divider>
      <ImageCard
        title={content.blurb_1_title}
        fixed={crowdmap.childImageSharp.fixed}
        alt={content.blurb_1_img_alt}
        body={content.blurb_1_body}
      >
        <StyledButton>
          <LocalLink to="/pricing">{content.get_started_btn}</LocalLink>
        </StyledButton>
      </ImageCard>
    </Divider>
  </PageContainer>
);

export default withLayout(IndexPage);

// get all data that is not meta, and put it in data.content.edges
// get meta data, and put it in data.meta.edges
export const query = graphql`
  query getIndex($Locale: String!) {
    mockup_1: file(name: { eq: "mockup_1" }) {
      childImageSharp {
        fixed(width: 360, height: 637) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    berlin_demo: file(name: { eq: "berlin_demo" }) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    crowdmap: file(name: { eq: "crowdmap" }) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    content: allAirtable(
      filter: { table: { eq: "Index" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
