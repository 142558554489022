import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { HR } from "./common";
import { media } from "../css/theme";

const WhiteBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin-bottom: 16px;
  && h2 {
    margin: 8px 0px;
  }
  img {
    width: 54px;
    height: 54px;
  }
  p {
    margin-bottom: 0;
    line-height: 1.2;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
    ${WhiteBorder} {
      max-width: 300px;
      margin: 16px 4px;
    }
  `};
`;

const SquareContentBox = ({ title, body, icon }) => (
  <WhiteBorder>
    <img src={icon} alt="icon" aria-hidden="true" />
    <h2>{title}</h2>
    <HR />
    <p>{body}</p>
  </WhiteBorder>
);

export default SquareContentBox;

SquareContentBox.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};
